$(document).on('ajax:error', function(event) {
  var errors = event.detail[0];
  $.each(errors, function(index, error) {
    var new_error = "<div class='container'>El formulario contiene los siguientes Errores: </br><ul><li class='text'>" + String(error).replace(/\,/g, '</li><li class="text">') +  "</li></ul></div>";
    $("#mainAlertMessageWarning").html(new_error);
    $('#mainAlertMessageWarning').show();
    window.scrollTo(0, 0);
  });

});

var Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut: function (rutCompleto) {
      rutCompleto = rutCompleto.replace("‐","-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
          return false;
      var tmp  = rutCompleto.split('-');
      var digv = tmp[1];
      var rut  = tmp[0];
      if (digv == 'K') digv = 'k';

      return (Fn.dv(rut) == digv );
  },
  dv: function(T){
      var M=0,S=1;
      for(;T;T=Math.floor(T/10))
          S=(S+T%10*(9-M++%6))%11;
      return S?S-1:'k';
  }
};

function fillParticipant(event, result){
  result = result || {}
  $("#"+event.target.id.replace("dni", "name1")).val(result.name1);
  $("#"+event.target.id.replace("dni", "name2")).val(result.name2);
  $("#"+event.target.id.replace("dni", "surname1")).val(result.surname1);
  $("#"+event.target.id.replace("dni", "surname2")).val(result.surname2);
  $("#"+event.target.id.replace("dni", "email")).val(result.email);
  $("#"+event.target.id.replace("dni", "mobile")).val(result.mobile);
  $("#"+event.target.id.replace("dni", "position")).val(result.position);
  if(result.nif_id != undefined)
  {
    $("#"+event.target.id.replace("dni", "nif_id")).val(result.nif_id);
  }
  $("#"+event.target.id.replace("dni", "id")).val(result.id);
  if(result.faena_id != undefined)
  {
    $("#"+event.target.id.replace("dni", "faena_id")).val(result.faena_id);
  }
}

$(document).on('submit', '.rut-form', function(event) {
  var rut = $(this).find("#rut").val();
  if(!Fn.validaRut(rut)) {
    $("#mainAlertMessageWarning").html("El Rut ingresado no es válido.");
    $('#mainAlertMessageWarning').collapse('show');
    event.preventDefault();
  }
  else {
    $('#mainAlertMessageWarning').collapse('hide');
  }
});

$(document).on('blur', '.rut-valid-company', function(event) {
  var rut = $(this).val();
  if(!Fn.validaRut(rut)) {
    $("#mainAlertMessageWarning").html("El Rut ingresado no es válido.");
    $('#mainAlertMessageWarning').collapse('show');
    event.preventDefault();
  } else{
    $('#mainAlertMessageWarning').collapse('hide');
  }
});

$(document).on('blur', '.rut-valid', function(event) {
  window.valido = true;
  var rut = $(this).val();
  var object = $(this).parents(".card").find(".msgerror");
  var family_id = $(this).data("family-id");
  if(!Fn.validaRut(rut) && rut.length > 0) {
    object.html("El Rut ingresado no es válido.").collapse('show');
    window.valido = false;
  } else{
    object.collapse('hide');
    var url = "/participants/" + rut +"/search_participant?family_id=" + family_id
    $.ajax({
      url: url,
      method: "GET",
      success: function(result) {
        var cc = result.course_code;
        if(result.course_code==undefined)
          cc = "";
        if(result.expired)
        {
          object.html("El participante tiene certificado válido hasta el: " +result.expired_date).collapse('show');
          window.valido = false;
          fillParticipant(event, null);
        } else{
          if(result.expired && cc != "")
          {
            object.html("El participante ya existe en un curso activo: " +cc).collapse('show');
            window.valido = false;
            fillParticipant(event, null);
          } else{
            window.valido = true;
            fillParticipant(event, result);
          }
        }
      },
      error: function(error) {
        object.html(error).collapse('show');
      }
    });
  }
});


$(document).on('click', '.delete-participant', function(event) {
  var url = $(this).data("url");
  var tipo =  $(this).data("tipo");
  if(tipo == "2")
    tipo = "¿Esta seguro que desea eliminar este participante?, si el curso es abierto dejaras el cupo disponible."
  else
    tipo = "¿Esta seguro que desea eliminar este participante?"
  if (confirm(tipo)){
    $.ajax({
      url: url,
      method: "DELETE",
      success: function(result) {
        window.location.reload();
      }
    });
  }
});

$(document).on('click', '.repeated-participant', function(event) {
  var stored  =   [];
  var inputs  =   $('.participant-rut');
  var flag    = false;

  $.each(inputs,function(k,v){
      var getVal  =   $(v).val();
      if(stored.indexOf(getVal) != -1){
        $("#mainAlertMessageWarning").html("El Rut " + getVal + " esta repetido.");
        $('#mainAlertMessageWarning').collapse('show');
        flag = true;
        window.scrollTo(0, 0);
        event.preventDefault();
        return false;
      }
      else{
        if(getVal != "")
          stored.push($(v).val());
      }
  });
  if(flag == false){
    $('#mainAlertMessageWarning').collapse('hide');
  }
});