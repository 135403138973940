// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import './bootstrap_custom.js';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.es.js';
import "@fortawesome/fontawesome-free/js/all";
import './remote_forms.js';
import '../stylesheets/application';
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

require("selectize")
$(document).on("turbolinks:load", function() {
  $('.selectize').selectize();
  $('.datepicker').datepicker({
    language: 'es',
    format: "yyyy-mm-dd",
  });
});


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const AwsS3Multipart = require('@uppy/aws-s3-multipart')
const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const Spanish = require('@uppy/locales/lib/es_ES')

const fillForm = (resp) => {
  resp.results.forEach((item, index) => {
    for (const [key, value] of Object.entries(item)) {
      let fieldId = "";
      if (window.location.href.includes("list_participants")) {
        fieldId = `participant_attributes_${index}_${key}`
      } else {
        fieldId = `course_evaluations_attributes_${index}_participant_attributes_${key}`
      }
      document.getElementById(fieldId).value = value;
    }
  });
}

const setupUppy = () => {
  let trigger = document.querySelector('#uppy-trigger')

  if (!trigger) {
    return
  }

  trigger.addEventListener("click", (event) => event.preventDefault())

  let uppy = Uppy({
    debug: true,
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      allowedFileTypes: ['.xlsx']
    }
  })

  uppy.use(Dashboard, {
    trigger: trigger,
    locale: Spanish
  })

  uppy.use(AwsS3Multipart, {
    companionUrl: '/',
  })

  uppy.on('complete', (result) => {
    if (!result.successful.length > 0) {
      return
    }

    let token = document.head.querySelector('meta[name="csrf-token"]').content;
    let file = result.successful[0];
    let url = file.uploadURL;

    fetch('/upload/participants', { method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ authenticity_token: token, url: url })
    }).then(res => res.json())
      .then(res => {
        fillForm(res)
        uppy.getPlugin('Dashboard').closeModal()
      })
  })
}

document.addEventListener('turbolinks:load', () => {
  setupUppy();
})